import NiceModal from "@ebay/nice-modal-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.scss';
import { Routes } from "./Routes";
function App() {
  return (
    <NiceModal.Provider>
      <RouterProvider router={createBrowserRouter(Routes)} />
    </NiceModal.Provider>
  );
}
export default App;
