import AOS from "aos";
import "aos/dist/aos.css";
import { Col, Row } from "react-bootstrap";
import mobile from "../../../assets/images/mobile-hero.png";
import logo from "../../../assets/images/Unix logo.png";
import CommonButton from "../../../components/common/commonButton/CommonButton";
import "./ComingSoon.scss";

const ComingSoon = () => {

    AOS.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 100,
    });

    AOS.init({
        disable: 'phone'
    });

    return (
        <>
            <section className="comingsoon">
                <div className="comingsoon_welcome">
                    <div className="comingsoon_welcome_header">
                        <img src={logo} alt="unix_logo" />
                        <CommonButton title="Coming soon" className="btn-secondary" />
                    </div>
                    <div className="comingsoon_welcome_text">
                        <Row>
                            <Col xl={12} xxl={6}>
                                <div className="comingsoon_welcome_text_left" data-aos="slide-right" >
                                    <img src={mobile} alt="mobile_img" />
                                    <h2>A new onchain era dawns on</h2>
                                    <h1>CrossFi</h1>
                                    <CommonButton title="Coming soon" className="btn-secondary" />
                                    <p>Welcome Home</p>
                                </div>
                            </Col>
                            <Col xl={12} xxl={6}>
                                <div className="comingsoon_welcome_text_right">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="comingsoon_welcome_features">
                    <Row>
                        <Col xs={12} sm={12} lg={4}>
                            <div className="comingsoon_welcome_features_left" data-aos="zoom-in" data-aos-delay="100">
                                <h2>Our Features</h2>
                                <div className="feature_card">
                                    <div className="feature_card_content">
                                        <h4>Concentrated Liquidity</h4>
                                        <p>
                                            UniX introduces concentrated liquidity, allowing liquidity to be allocated within a custom price range. Earlier versions distributed liquidity uniformly along the entire price curve.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <div className="comingsoon_welcome_features_left">
                                <div className="feature_card swap" data-aos="zoom-in" data-aos-delay="200">
                                    <div className="feature_card_content">
                                        <h4>Swap</h4>
                                        <p>
                                            Experience multi-tier fees via prompt Auto Router.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature_card farms" data-aos="zoom-in" data-aos-delay="300">
                                    <div className="feature_card_content">
                                        <h4>Farms</h4>
                                        <p>
                                            Automatically participate in V3 farming with high APY.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <div className="comingsoon_welcome_features_left btm">
                                {/* <div data-aos="zoom-in"> */}
                                <div className="feature_card analytics" data-aos="zoom-in" data-aos-delay="400">
                                    <div className="feature_card_content">
                                        <h4>Analytics</h4>
                                        <p>
                                            Manage income by keeping track of crucial data.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature_card liquidity" data-aos="zoom-in" data-aos-delay="500">
                                    <div className="feature_card_content">
                                        <h4>Liquidity</h4>
                                        <p>
                                            Provide V3 liquidity to gain 80% LP rewards from trading fees.
                                        </p>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="comingsoon_welcome_footer">
                    <img src={logo} alt="unix_logo" />
                    <p>© UniX 2024</p>
                </div>
            </section>
        </>
    );
};

export default ComingSoon;