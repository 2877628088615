import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Arbitrum from '../../../assets/images/chain/arbit.png';
import Avalanche from '../../../assets/images/chain/ava.png';
import Base from '../../../assets/images/chain/base.png';
import Blast from '../../../assets/images/chain/blast.png';
import BNB from '../../../assets/images/chain/bnb.png';
import Celo from '../../../assets/images/chain/celo.png';
import Ethereum from '../../../assets/images/chain/eth.png';
import ZKsync from '../../../assets/images/chain/jk.png';
import Optimism from '../../../assets/images/chain/opti.png';
import Polygon from '../../../assets/images/chain/poly.png';
import Zora from '../../../assets/images/chain/zora.png';
import { TickIcon } from "../../../assets/svgIcons/svgIcons";
import './ChainSelector.scss';
const ChainSelector = () => {
    const [active, setActive] = useState(0);
    const data = [
        {
            icon: Ethereum,
            title: "Ethereum"
        },
        {
            icon: Arbitrum,
            title: "Arbitrum"
        },
        {
            icon: Optimism,
            title: "Optimism"
        },
        {
            icon: Polygon,
            title: "Polygon"
        },
        {
            icon: Base,
            title: "Base"
        },
        {
            icon: BNB,
            title: "BNB Chain"
        },
        {
            icon: Avalanche,
            title: "Avalanche"
        },
        {
            icon: Celo,
            title: "Celo"
        },
        {
            icon: Blast,
            title: "Blast"
        },
        {
            icon: Zora,
            title: "Zora Network"
        },
        {
            icon: ZKsync,
            title: "ZKsync"
        },

        
    ]
    return (
        <Dropdown className="chain_wrapper" >
            <Dropdown.Toggle id="chain">
                <img src={data[active]?.icon} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
                {data.map((item, index) => {
                    return (
                        <Dropdown.Item href="#" key={index} className={active === index ? "active" : ""}  onClick={()=>{setActive(index)}}><img src={item.icon} alt="" /> {item.title} {active === index ? <TickIcon/> : ""}</Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default ChainSelector