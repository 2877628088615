import { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import "./NewLayout.scss";
const NewLayout = () => {

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <main className="new_layout">
                <Outlet />
            </main>
        </>
    );
};

export default NewLayout;