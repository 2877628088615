import InnerLayout from "./components/common/layouts/innerLayout/InnerLayout";
import NewLayout from "./components/common/layouts/newLayout/NewLayout";
import ComingSoon from "./pages/public/comingSoon/ComingSoon";
import NotFound from "./pages/public/notFound/NotFound";
import Pool from "./pages/public/pool/Pool";
import Trade from "./pages/public/trade/Trade";


// const publicRoutes = ([
//     {
//         index: true,
//         element: <Trade />,
//         //   loader: rootLoader,
//     },
//     {
//         path: "/pool",
//         element: <Pool />,
//         //   loader: rootLoader,
//     },

// ]);

const newRoutes = ([
    {
        path: "/",
        element: <ComingSoon />,
    },
])

export const Routes = [
    // {
    //     path: "/",
    //     element: <InnerLayout />,
    //     errorElement: <NotFound />,
    //     children: publicRoutes,
    // },
    {
        path: "/",
        element: <NewLayout />,
        errorElement: <NotFound />,
        children: newRoutes,
    },
]
