import NiceModal from "@ebay/nice-modal-react";
import INCH from "../../../assets/images/icon/1inch.png";
import AAVE from "../../../assets/images/icon/aave.png";
import ADX from "../../../assets/images/icon/adx.png";
import AIOZ from "../../../assets/images/icon/aioz.png";
import APE from "../../../assets/images/icon/ape.png";
import DAI from '../../../assets/images/icon/dai.png';
import ETH from '../../../assets/images/icon/ethereum.png';
import USDC from '../../../assets/images/icon/usdc.png';
import USDT from '../../../assets/images/icon/usdt.png';
import WBTC from '../../../assets/images/icon/wbtc.png';
import WETH from '../../../assets/images/icon/weth.png';
import ZRX from "../../../assets/images/icon/zrx.png";
import { SearchIcon } from "../../../assets/svgIcons/svgIcons";
import ChainSelector from "../../common/chainSelector/ChainSelector";
import CommonModal from "../CommonModal";
import './TokenModal.scss';
const TokenModal = NiceModal.create(
  ({ closeTokenModal }: any) => {
    const data = [
      { icon: ETH, title: "ETH" },
      { icon: DAI, title: "DAI" },
      { icon: USDC, title: "USDC" },
      { icon: USDT, title: "USDT" },
      { icon: WBTC, title: "WBTC" },
      { icon: WETH, title: "WETH" },
    ]
    const list = [
      { icon: ZRX, title: "0x Protocol", subtitle: "ZRX" },
      { icon: INCH, title: "1inch", subtitle: "1INCH" },
      { icon: AAVE, title: "Aave", subtitle: "AAVE" },
      { icon: ADX, title: "AdEX", subtitle: "ADX" },
      { icon: AIOZ, title: "AIOZ Network", subtitle: "AIOZ" },
      { icon: APE, title: "ApeCoin", subtitle: "APE" },
    ]
    return (
      <CommonModal
        className="tokenmodal"
        show
        onHide={closeTokenModal}
        heading="Select a token"
      >
        <div className="tokenmodal_search">
          <div className="tokenmodal_search_input">
            <SearchIcon/>
            <input type="search" placeholder="Search name or paste address" />
          </div>
          <ChainSelector />
        </div>
        <div className="tokenmodal_quick_sug">
          <ul>
            {data.map((item, index) => {
              return (
                <li key={index}><button type="button" onClick={closeTokenModal}><img src={item.icon} alt="" /> {item.title}</button></li>
              )
            })}
          </ul>
        </div>
        <div className="tokenmodal_quick_list">
          <h6>Popular tokens</h6>
          <ul>
            {list.map((item, index) => {
              return (
                <li key={index}><button type="button" onClick={closeTokenModal}><img src={item.icon} alt="" /> {item.title}<span>{item.subtitle}</span></button></li>
              )
            })}
          </ul>
        </div>
      </CommonModal>
    );
  }
);
export default TokenModal;
