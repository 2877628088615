import { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CommonButton.scss";

const CommonButton = ({
  title,
  className,
  svgIcon,
  imgicon,
  onClick,
  to,
  role,
  type,
  disabled,
  isLoading,
  fluid,
  text,
  children,
  ref,
  value,
  name,
}: {
  title?: string;
  className?: string;
  svgIcon?: ReactNode;
  imgicon?: string;
  onClick?: any;
  to?: any;
  id?: string;
  role?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  isLoading?: boolean;
  fluid?: boolean;
  text?: string;
  children?: ReactNode;
  ref?: any;
  value?: any;
  name?: any;
}) => {
  return (
    <>
      {(() => {
        switch (role) {
          case "link":
            return (
              <Link
                to={to}
                className={`common_btn ${fluid ? "full" : ""} ${className}`}
                ref={ref}
                onClick={onClick}
              >
                {svgIcon && (
                  <>
                    <span className="common_btn_icon">{svgIcon}</span>
                  </>
                )}
                {imgicon && (
                  <>
                    <span className="common_btn_icon">
                      <img
                        src={imgicon}
                        alt="" 
                      />
                    </span>
                    &nbsp;
                  </>
                )}
                {title && <span className="common_btn_title">{title}</span>}
                {isLoading ? <Spinner /> : text}
                {children}
              </Link>
            );
          default:
            return (
              <button
                value={value}
                name={name}
                className={`common_btn ${fluid ? "full" : ""} ${className}`}
                onClick={onClick}
                disabled={disabled}
                type={type ? type : "button"}
                ref={ref}
              >
                {svgIcon && (
                  <>
                    <span className="common_btn_icon">{svgIcon}</span>
                  </>
                )}
                {imgicon && (
                  <>
                    <span className="common_btn_icon">
                      <img
                        src={imgicon}
                        alt="" 
                      />
                    </span>
                    &nbsp;
                  </>
                )}
                {title && <span className="common_btn_title">{title}</span>}
                {/* {
                    loader && <Loader />
                  } */}
                {isLoading ? <Spinner /> : text}
                {children}
              </button>
            );
        }
      })()}
    </>
  );
};

export default CommonButton;
